import type { BillingAdditionalData } from "@warrenio/api-spec/spec.oats.gen";
import * as billing from "@warrenio/api-spec/spec/billing";
import type { BillingAccountWithType } from "./billingAccountQuery.ts";

export function parseAdditionalData(item: BillingAccountWithType) {
    const { additional_data } = item;
    if (!additional_data) {
        return undefined;
    }
    const data = JSON.parse(additional_data) as BillingAdditionalData;
    if (import.meta.env.DEV) {
        // Validate
        billing.BillingAdditionalData.parse(data);
    }
    return data;
}
