import type { QueryClient } from "@tanstack/query-core";
import type {
    CreditBuyBody,
    LinkParams,
    LinkProcessorId,
    LinkSendDataResponse,
    ProcessorCurrencyFee,
} from "@warrenio/api-spec/spec.oats.gen";
import { notNull } from "@warrenio/utils/notNull";
import sleep from "@warrenio/utils/promise/sleep";
import { jsonEncodedBody } from "../../../utils/fetchClient.ts";
import { invalidateQueries } from "../../../utils/query/invalidateQueries.ts";
import { UnmountedError } from "../../../utils/react/useUnmountSignal.tsx";
import { getResponseData, type ApiClient } from "../../api/apiClient.ts";
import * as billingAccountQuery from "../billingAccountQuery.ts";
import * as billingHistoryQuery from "../billingHistoryQuery.ts";
import * as billingInvoicesQuery from "../billingInvoicesQuery.ts";
import type { EBillingAccount } from "../billingLogic.tsx";
import type { MethodBase } from "../PaymentMethod.tsx";
import type { PaymentPopup } from "./popup.ts";
import { TopUpResult, type TopUpParams } from "./TopUpParams.ts";

/** Initiate the top-up process for a link-type processor */
export async function linkSendData<T extends LinkParams>(
    apiClient: ApiClient,
    { account, amount, returnUrl }: TopUpParams,
    processor: LinkProcessorId,
    params: Omit<T, "referrer_url">,
): Promise<LinkSendDataResponse> {
    const billing_account_id = account.id;

    const extendedParams = {
        referrer_url: returnUrl,
        ...params,
    } satisfies LinkParams;

    // TODO: Check `success` and `error_msg` fields?
    return getResponseData(
        await apiClient.POST("/payment/link/send_data", {
            ...jsonEncodedBody,
            body: {
                billing_account_id,
                amount,

                params: JSON.stringify(extendedParams),
                processor,
            },
        }),
    );
}

export async function creditBuyRequest(apiClient: ApiClient, body: CreditBuyBody) {
    return getResponseData(
        await apiClient.POST("/payment/credit/buy", {
            ...jsonEncodedBody,
            body,
        }),
    );
}

export async function persistentDataRequest(
    apiClient: ApiClient,
    billing_account_id: number,
    identifier: string,
    signal: AbortSignal,
) {
    return getResponseData(
        await apiClient.GET("/payment/persistent_data", {
            params: {
                query: { billing_account_id, identifier },
            },
            signal,
        }),
    );
}

export async function paymentResultRequest(
    apiClient: ApiClient,
    billing_account_id: number,
    identifier: string,
    signal: AbortSignal,
) {
    return getResponseData(
        await apiClient.GET("/payment/link/process_result", {
            params: {
                query: { billing_account_id, identifier },
            },
            signal,
        }),
    );
}

export async function creditListRequest(apiClient: ApiClient, billing_account_id: number, signal: AbortSignal) {
    return getResponseData(
        await apiClient.GET("/payment/credit/list", {
            signal,
            params: { query: { billing_account_id } },
        }),
    );
}

export async function finalTopupAmountRequest(
    apiClient: ApiClient,
    params: {
        billing_account_id: number;
        processor: string;
        amount: number;
    },
) {
    const { billing_account_id, processor, amount } = params;
    return getResponseData(
        await apiClient.GET("/payment/final_topup_amount", {
            params: { query: { billing_account_id, processor, amount } },
        }),
    );
}

export function getRedirectUrlFromData(data: { redirect_url: string | string[] }) {
    const { redirect_url } = data;
    return Array.isArray(redirect_url) ? notNull(redirect_url[0], "redirect_url") : redirect_url;
}

export async function invalidateTopUpQueries(queryClient: QueryClient, billing_account_id: number) {
    await invalidateQueries({
        queryClient,
        immediate: [billingAccountQuery.queryKey, billingHistoryQuery.getQueryKey({ billing_account_id })],
        background: [billingInvoicesQuery.getQueryKey({ billing_account_id })],
    });
}

// TODO: Rename to `getCurrencyFeeConfig`
// TODO: Move fee calculations to a separate module
export function getCurrencyConversion(
    account: EBillingAccount,
    conversion: ProcessorCurrencyFee | undefined,
    country: string | undefined,
): ProcessorCurrencyFee | undefined {
    const baCountry = account.account.country?.toUpperCase();
    const cardCountry = country?.toUpperCase();
    if (!baCountry) {
        console.warn(`Account ${account.id} has no country set`);
        return undefined;
    }

    if (conversion?.to_currency === undefined || conversion.exchange_rate === undefined) {
        return undefined;
    }

    const forCountry = conversion.for_country;
    const countryExists: boolean = !!cardCountry || !!baCountry;
    if (!!forCountry && countryExists && forCountry !== cardCountry && forCountry !== baCountry) {
        return undefined;
    }

    return conversion;
}

export interface CalculatedFees {
    baseFee: number;
    feePercent: number;
    total: number;
}

// TODO: Unify VAT and currency conversion fee calculations
export function getCalculatedFees(fees: MethodBase["fee"], amount: number, vat_percentage: number): CalculatedFees {
    let baseFee = 0;
    let feePercent = 0;
    if (fees) {
        baseFee = fees.service_fee?.flat_rate ?? 0;
        feePercent = fees.service_fee?.percentage ?? 0;
    }

    const totalFee = baseFee + amount * (feePercent / 100);
    const vatMultiplier = 1 + vat_percentage / 100;
    const total = (amount + totalFee) * vatMultiplier;
    return { baseFee, feePercent, total };
}

export interface PollOptions {
    signal: AbortSignal;
    check: () => Promise<boolean>;
    popup: PaymentPopup | undefined;
    pollInterval?: number;
    maxPollCount?: number;
}

export async function pollForSuccess({
    signal,
    check,
    pollInterval = 5000,
    maxPollCount = 10,
    popup,
}: PollOptions): Promise<TopUpResult> {
    try {
        // NB: This loop is exited with an abort error whenever the signal is set (eg. when the component is unmounted)
        for (let pollCount = 0; ; pollCount++) {
            if (maxPollCount != null && pollCount > maxPollCount) {
                throw new PaymentPollTimeoutError();
            }
            await sleep(pollInterval, signal);

            if (await check()) {
                return TopUpResult.SUCCESS;
            }

            if (popup) {
                console.debug("%s: Checking popup state: %o", pollCount, popup.toString());
                if (popup.closed) {
                    console.log("Popup closed");
                    // TODO: Handle this case? Status message should go back to main window
                }
            }
        }
    } catch (e) {
        if (e instanceof UnmountedError) {
            console.debug("Unmounted, stopping polling");
            return TopUpResult.CANCELLED;
        } else {
            throw e;
        }
    }
}

export class PaymentPollTimeoutError extends Error {
    name = "PaymentPollTimeoutError";

    constructor() {
        super(
            "Response seems to be taking more time than expected. Wait a bit and refresh the page to see credit changes reflected. If it doesn't happen in a reasonable time, please contact support.",
        );
    }
}

export function failureToMessage({
    failure_code,
    failure_message,
}: {
    failure_code?: string;
    failure_message?: string;
}) {
    let message;
    if (failure_message) {
        message = `Payment error: ${failure_message}`;
    } else {
        message = "Unknown payment error";
    }
    if (failure_code) {
        message += ` [${failure_code}]`;
    }
    return message;
}
